<template>
	<div class="join-pitch">
		<div class="image-container">
			<img
				src="/images/user-group-1.webp"
				width="128"
				height="44"
				alt="join pitch image"
			/>
		</div>

		<span :class="props.isWhite && 'text-white'">Join 43,323+ traders inside</span>
	</div>
</template>

<script setup>
const props = defineProps({
  isWhite: Boolean
});
</script>
<style lang="scss" scoped>
@import "../assets/utils.scss";

.image-container {
	display: flex;

	img {
		position: relative;
		height: 40px;
		width: auto;

		&:not(:first-child) {
			margin-left: -20px;

			@include breakpoint(lg) {
				margin-left: -16px;
			}
		}

		@include breakpoint(lg) {
			height: 44px;
		}
	}
}

.join-pitch {
	display: flex;
	align-items: center;
	gap: 16px;

	span {
		color: $neutral-50;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%;
		opacity: 0.5;

		&.text-white {
			color: white !important;
			opacity: 1;
		}
	}
}
</style>
