<template>
	<span
		v-if="to"
		class="block mx-auto relative"
	>
		<NuxtLink
			ref="button"
			:href="to"
			:target="blank ? '_blank' : ''"
			:class="`cta btn ${type === 'secondary' && 'secondary'}`"
		>
			<slot />
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M14 4.92993L12.5 6.42993L17.0703 11.0002H3V13.0002H17.0703L12.5 17.5706L14 19.0706L21.0703 12.0002L14 4.92993Z"
					:fill="`${type === 'secondary' ? 'white' : '#1E293B'} `"
				/>
			</svg>
		</NuxtLink>
	</span>
	<button
		v-else
		type="submit"
		:class="`cta btn ${type === 'secondary' && 'secondary'}`"
	>
		<slot />
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14 4.92993L12.5 6.42993L17.0703 11.0002H3V13.0002H17.0703L12.5 17.5706L14 19.0706L21.0703 12.0002L14 4.92993Z"
				:fill="`${props.type === 'secondary' ? 'white' : '#1E293B'} `"
			/>
		</svg>
	</button>
</template>

<script setup>
const props = defineProps({
	type: {
		type: String,
		required: false,
		default: ""
	},
	to: {
		type: String,
		required: false,
		default: ""
	},
	blank: {
		type: Boolean,
		default: false
	}
});

const button = ref();

onMounted(() => {});
</script>

<style lang="scss" scoped>
@import "../../assets/utils.scss";

.btn.cta {
	display: flex;
	padding: 12px 16px;
	align-items: center;
	gap: 4px;
	border-radius: 8px;
	background: $warning-500;
	color: $neutral-800;
	text-align: center;
	font-family: Fira Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 25px;
	white-space: nowrap;

	&.secondary {
		background-color: $primary-700;
		color: white;
		&:hover {
			background-color: $primary-500;
		}
	}

	@include breakpoint(md) {
		padding: 12px 24px;

		font-size: 18px;
	}

	&:hover {
		background: $secondary-500;
	}
}

a.btn.cta {
	display: inline-flex;
}
</style>
