<template>
	<form
		:id="props.id"
		accept-charset="UTF-8"
		action="https://eo109.infusionsoft.com/app/form/process/d80ac2a9563842fabd54c1b9f9c787a5"
		method="POST"
		class="infusion-form form-watchlist-cta"
		target="_blank"
		@submit.prevent="formSubmitHandler"
	>
		<input
			:id="formLeadId"
			name="inf_field_Email"
			type="email"
			placeholder="Your email address"
			class="infusion-field-input-container author-watchlist-information-email-input"
			required
		/>
		<input
			name="inf_form_xid"
			type="hidden"
			value="d80ac2a9563842fabd54c1b9f9c787a5"
		/>
		<input
			name="inf_form_name"
			type="hidden"
			value="Tim Sykes Watchlist"
		/>
		<input
			name="infusionsoft_version"
			type="hidden"
			value="1.70.0.58116"
		/>
		<CtaButton>Get FREE Watchlist</CtaButton>
	</form>
</template>
<script setup>
import { modifyAndSubmitForm } from "@/utils/generic";

const props = defineProps({
  id: {
    type: String, 
    required: true
  }
});

const formLeadId = computed(() => {
	return `inf_field_Email-${props.id}`;
});

const formSubmitHandler = async () => {
	await modifyAndSubmitForm(formLeadId.value);

	await setTimeout(() => {
		document.getElementById(props.id).submit();
	}, 10);
};
</script>
<style lang="scss">
@import "../assets/utils.scss";

.form-watchlist-cta {
	display: flex;
	width: 100%;
	padding: 6px;
	align-items: center;
	border-radius: 8px;
	background: $primary-50;
	justify-content: space-between;
	flex-direction: column;

	@include breakpoint(md) {
		flex-direction: row;
		gap: 10px;
	}

	@include breakpoint(md) {
		width: 500px;
	}

	button {
		width: 100%;
		justify-content: center;
		@include breakpoint(md) {
			width: auto;
		}
	}

	input {
		background-color: transparent;
		color: $neutral-800;
		padding: 10px 12px;
		font-size: 18px;
		font-weight: 400;
		line-height: 160%;
		width: 100%;

		&::placeholder {
			font-weight: 400;
			color: $neutral-400;
			padding: 10px 12px;
		}

		@include breakpoint(lg) {
			padding: 10px;
		}
	}
}
</style>
